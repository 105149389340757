@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.6.3/css/font-awesome.css);
@font-face {
  font-family: 'Scandia web Regular';
  src: url('./Scandia/Scandia.woff') format('woff');
}
body {
  font-family: Scandia web Regular !important;
  font-style: normal;
  font-weight: normal;
}
.padding {
  padding: 4rem;
}
.carousel-dark .carousel-control-next-icon,
.carousel-dark .carousel-control-prev-icon {
  filter: none !important;
}
.carousel-control-next-icon {
  background-color: black;
  border-radius: 34%;
  height: 2.5rem !important;
  padding: 5%;
}
.carousel-control-prev-icon {
  background-color: black;
  border-radius: 34%;
  height: 2.5rem !important;
  padding: 5%;
}
.carousel-dark .carousel-indicators [data-bs-target] {
  border: 0.7px solid #ffffff0f;
  border-radius: 18% !important;
  height: 1rem !important;
  margin-left: 8px !important;
  margin-right: 8px !important;
}
/*rgb(243 255 51);*/
.closeButton {
  display: none;
}
.nosotrosHome {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40rem;
  background-repeat: no-repeat, no-repeat, no-repeat;
  background-size:
    34% 100%,
    34% 100%,
    34% 100%;
  background-position:
    left center,
    center center,
    right center;
}
.nosotrosGaleria {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40rem;
  background-repeat: no-repeat, no-repeat, no-repeat;
  background-size:
    25% 100%,
    50% 100%,
    25% 100%;
  background-position:
    left center,
    center center,
    right center;
}
.titulo {
  font-size: 78px;
}
.nosotrosInfo p {
  margin-left: 22vw;
  padding-top: 5vh;
  font-size: 20px;
}
.imgCasamiento {
  width: 43vw;
  margin-left: 0vw;
  height: 44vh;
}
/* From uiverse.io by @Custyyyy */
#bottone1 {
  padding-left: 5rem;
  padding-right: 5rem;
  padding-bottom: 10px;
  padding-top: 20px;
  border-radius: 9px;
  color: rgb(23 23 24);
  background: rgb(243 255 51);
  border: none;
  font-family: inherit;
  text-align: center;
  cursor: pointer;
  transition: 0.1s;
}

#bottone1:hover {
  box-shadow: 7px 5px 56px -14px #c3d900;
}

#bottone1:active {
  transform: scale(0.97);
  box-shadow: 7px 5px 56px -10px #c3d900;
}

.datos {
  height: 17rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.contactoFooter {
  height: 8rem;
  margin-bottom: 4rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.footer {
  color: rgb(243 255 51);
  font-size: 35px !important;
  font-weight: 300;
}
.datos p {
  color: aliceblue;
}
.contactoFooter h1 {
  font-size: 17px !important;
  color: aliceblue;
}
.contactoFooter p {
  color: aliceblue;
}
.nav-link {
  color: #f1f1f18a !important ;
  position: relative;
  z-index: 10;
}
.nav-link:hover {
  color: white !important;
}
.navbar-brand {
  margin-right: 0 !important;
}
.comoLlegar {
  width: 50%;
}
#navInicio .inicio {
  color: #c3d900 !important;
}
#navNosotros .nosotros {
  color: #c3d900 !important;
}
#navEventos .venue {
  color: #c3d900 !important;
}
#navContacto .contacto {
  color: #c3d900 !important;
}
#navLogo {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.datos div:hover p {
  color: #c3d900;
}
#logoMobile {
  display: none;
}
.container {
  display: flex;
  justify-content: center !important;
}
.navbar-collapse {
  flex-grow: unset !important;
}
.navbar-nav {
  margin-left: 3vw;
}
.navbar-light .navbar-toggler-icon {
  filter: invert(1);
}
#comoLlegar {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
#mapa2 {
  width: 50%;
}
#textMapa {
  margin-bottom: 15%;
}
#mapa {
  width: 100%;
}
strong {
  font-size: 22px;
}
a {
  text-decoration: none !important;
}
.instagram {
  color: rgb(23 23 24);
  background-color: #c3d900;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
}
.homeVideos {
  display: flex;
  justify-content: space-around;
  padding-bottom: 5%;
  padding-top: 5%;
}
.homeVideos video {
  object-fit: cover;
  border-radius: 25px;
  box-shadow:
    16px 16px 32px #181818,
    -16px -16px 32px #202020;
  width: 30%;
  filter: grayscale(1);
  border: 3.5px solid rgb(245, 255, 53);
}
.homeVideos video:hover {
  filter: grayscale(0);
}
.imgTorneo img {
  filter: drop-shadow(black 2px 4px 6px);
}
.fa-phone {
  color: rgb(23 23 24);
  background-color: #c3d900;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
}
.instagram:hover {
  background-color: white;
}
.fa-phone:hover {
  background-color: white;
}
#socialIcons {
  display: flex;
  margin-left: 10%;
  z-index: 1000;
}
#socialIcons a {
  padding-left: 5px;
}

.contenedor {
  padding-top: 8rem;
  padding-left: 12rem;
}
.canchas {
  height: 67rem;
}
.nuestroEspacio-text {
  grid-gap: 3%;
}
.divOrg {
  display: flex;
  height: 96vh;
}
.divOrg h1 {
  font-size: 23px;
  font-weight: 400;
}
.ofrecemos {
  color: white;
  height: 35vh;
  padding-top: 5%;
}
.ofrecemos h1 {
  font-size: 30px;
  font-weight: 200;
}
.ofrecemos p {
  font-size: 18px;
  font-weight: 200;
}
.displayNone {
  opacity: 0;
}
.videoButton1 {
  opacity: 1;
}
.videoButton2 {
  opacity: 1;
}
.videoButton3 {
  opacity: 1;
}
.fotos4x4 {
  padding: 9%;
  position: relative;
  bottom: 15vw;
}
.fotos4x4 img {
  border-radius: 15px;
  width: 50%;
  padding: 3px;
}
.eventos4x4Cont {
  width: 100%;
  background-color: white;
  margin-top: 7vw;
  height: 56vw;
}
.eventosButton {
  left: 40%;
}
.eventosButton h1 {
  font-size: 25px !important;
}
@media (max-width: 991px) {
  .homeVideos video {
    height: 200px;
    border-radius: 18px;
  }
  .eventos4x4Cont {
    height: 70vw;
  }
  .eventosButton h1 {
    font-size: 13px !important;
  }
  .ofrecemos h1 {
    font-size: 20px;
    font-weight: 200;
  }
  .ofrecemos p {
    font-size: 13px;
    font-weight: 100;
  }
  .contt {
    width: 100% !important;
    font-size: 12px;
  }
  .textTorneo {
    padding: 5%;
  }
  .divCasamiento h1 {
    font-size: 28px !important;
  }
  .divCasamiento p {
    color: white !important;
    font-size: 15px !important;
  }
  .eventosAbout p,
  strong {
    font-size: 14px !important;
  }
  .nuestroEspacio {
    display: grid;
  }
  .nuestroEspacio-text {
    z-index: 10;
    width: 100% !important;
  }
  .container {
    justify-content: space-between !important;
  }
  #bottone1 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  #logoMobile {
    display: initial;
    height: 80px;
    width: 130px !important;
  }
  .nosotrosInfo p {
    margin-left: 2rem !important;
    padding-top: 1rem;
    font-size: 18px;
  }
  #navLogoA {
    display: none;
  }
  .nav-link {
    margin: 0 !important;
  }
  .nosotrosHome {
    background-size:
      100% 34%,
      100% 34%,
      100% 34%;
    background-position:
      top center,
      center center,
      bottom center;
  }
  .nosotrosGaleria {
    background-size:
      100% 34%,
      100% 34%,
      100% 34%;
    background-position:
      top center,
      center center,
      bottom center;
  }
  .datos {
    height: 20rem;
    display: grid;
    grid-template-columns: repeat(2, 0fr);
    text-align: center;
    align-content: space-evenly;
    align-items: baseline;
  }
  #comoLlegar {
    height: auto;
    display: initial;
  }
  #mapa2 {
    width: 100%;
  }
  #textMapa {
    margin-top: 5%;
    margin-bottom: 5%;
    text-align: center;
  }
  #socialIcons {
    position: relative;
    left: 77%;
    top: -35px;
    margin-left: 0;
  }
  .navbar-nav {
    margin-left: 0;
  }
  .container,
  .container-md,
  .container-sm {
    max-width: 100% !important;
  }
  .storeButton img {
    margin-right: 0 !important;
    max-width: 140px !important;
  }
  .contactoFooter {
    display: grid;
    justify-content: center;
    margin-bottom: 13rem;
  }
  .titulo {
    font-size: 25px;
  }
  .imgTorneo {
    display: none;
  }
  .cont {
    height: 5rem !important;
  }
  .cont h1 {
    font-size: 15px !important;
  }
  .cont strong {
    font-size: 15px !important;
  }
  .contenedor {
    padding-top: 16rem;
    padding-left: 0rem;
  }
  .contenedor div {
    width: 100% !important;
    padding: 0px !important;
    height: 510px !important;
  }
  .contenedorWhite {
    height: 31rem !important;
  }
  .canchas {
    height: 20rem;
  }
  .canchas .titulo {
    top: 14px !important;
    font-size: 44px;
  }
  .imgTorneo div {
    padding-top: 10px;
  }
}
#contact-form {
  border-radius: 37px;
  box-shadow:
    16px 16px 32px #181818,
    -16px -16px 32px #202020;
}
.nosotrosInfo p,
strong {
  color: black;
}
.nosotrosInfo strong {
  font-size: 15px;
}
.homeVideos {
  max-height: 90vh;
}
.activeVideo {
  position: relative;
  bottom: 5vh;
}
@media (max-height: 900px) {
  .nosotrosInfo {
    height: 35vw !important ;
  }

  .textTorneo {
    padding: 5%;
  }
  .canchas #bottone1 {
    padding-top: 15px;
  }
  .eventos4x4Cont {
    height: 75vw;
    margin-top: 14vw;
  }
  .fotos4x4 {
    padding: 9% 2% 9% 2%;
    bottom: 20vw;
  }
  #bottone1 {
    padding-top: 14px;
  }
  .navbar-nav {
    margin-left: 0 !important;
  }
}
@media (max-height: 667px) {
  .eventosAbout {
    height: 45vh !important;
  }
  .spanAeria {
    height: 17px !important;
  }
}
@media (max-width: 1300px) {
  .imgCasamiento {
    width: 47%;
  }
}
@media (min-width: 1300px) {
  .contenedorTorneo p {
    font-size: 20px;
  }
  .contenedorTorneo strong {
    font-size: 20px;
  }
}
@media (min-width: 992px) and (max-width: 1300px) {
  .divCasamiento div {
    padding: 5vw 5vh 4vw 12vh !important;
  }
  .divCasamiento {
    margin-right: 7vh !important;
  }
  .divCasamiento h1 {
    font-size: 28px !important;
  }
  .divCasamiento p {
    font-size: 14px !important;
  }
}
@media (min-width: 1301px) and (max-width: 1800px) {
  .divCasamiento h1 {
    font-size: 31px !important;
  }
  .divCasamiento p {
    font-size: 17px !important;
  }
}
@media (min-width: 2000px) {
  .contenedorTorneo p,
  h1 {
    font-size: 22px;
  }
  .nosotrosInfo {
    height: 25vh !important;
  }
  .nosotrosInfo p {
    font-size: 25px;
  }
  .nosotrosInfo strong {
    font-size: 25px;
  }
  .contenedor p {
    font-size: 20px;
  }
}
@media (min-width: 2200px) {
  .carouselDiv {
    margin-left: 12% !important;
    margin-right: 12% !important;
  }
}
@media (min-width: 2500px) {
  .carouselDiv {
    margin-left: 14% !important;
    margin-right: 14% !important;
  }
  .textTorneo {
    margin-right: 5vw;
  }
}
@media (min-width: 1000px) and (max-width: 1600px) {
  .divOrg {
    height: 70vh;
  }
}
@media (min-height: 1000px) and (max-width: 991px) {
  .contactoFooter {
    margin-right: 40%;
  }
  .eventos4x4Cont {
    height: 63vw;
  }
}
