.menu h3{
    font-size: 1.5rem;
    margin-top: 1rem;
}
.neonText {
    animation: flicker 3s infinite alternate;
  }
  
  
  /* Flickering animation */

  @-webkit-keyframes flicker {
      
    0%, 18%, 22%, 25%, 53%, 57%, 100% {
  
        -webkit-text-shadow:
        0 0 4px black,
        0 0 11px black,
        0 0 19px black,
        0 0 30px rgb(243 254 52),
        0 0 60px rgb(243 254 52),
        0 0 70px rgb(243 254 52),
        0 0 80px rgb(243 254 52),
        0 0 100px rgb(243 254 52);
    
    }
    
    20%, 24%, 55% {        
        text-shadow: none;
    }    
  }
  @keyframes flicker {
      
    0%, 18%, 22%, 25%, 53%, 57%, 100% {
  
        text-shadow:
        0 0 4px black,
        0 0 11px black,
        0 0 19px black,
        0 0 30px rgb(243 254 52),
        0 0 60px rgb(243 254 52),
        0 0 70px rgb(243 254 52),
        0 0 80px rgb(243 254 52),
        0 0 100px rgb(243 254 52);
    
    }
    
    20%, 24%, 55% {        
        text-shadow: none;
    }    
  }
.trago{
    font-size: 22px !important;
    font-weight: 900;
}
.allin .lineaAmarilla{
    margin-top: 4vh !important;
}
.textoCombos{
    position: absolute;
    left: 0;
    right: 0;
    top:7rem;
    margin: auto;
    -webkit-transform: rotate(-90deg); 
    -moz-transform: rotate(-90deg);
}
.imgBottom1{
    position: absolute;
    height: 7rem;
    top:75rem;
    right:0;
}
.imgBottom2{
    position: absolute;
    right: 0;
    top:85rem;
    height: 6rem;
}
.textoY{
position: absolute;
margin-left: 40%;
bottom: 65vw;
background: rgb(40 40 39);
}
@media screen and (min-width: 1024px) {
    .textoSobreBorde{
        position: absolute;
        margin-top: -6vh;
        font-size: 50px !important;
    }
    .textoY {
        bottom: -40rem;
    }
    .trago{
        font-size: 5rem !important;
    }
    .allin p{
        font-size: 30px;
    }
    .imgBottom1 {
        height: 7rem;
        top: 112rem;
    }
    .imgBottom2 {
        height: 6rem;
        top: 125rem;
    }
}
  /* For Mobile Phones Portrait or Landscape View */
  @media screen and (max-device-width: 640px) {
    .menu h3{
        font-size: 0.8rem;
    }
    .textoCombos{
        top:16rem;
    }
    .imgBottom1{
        height:40px;
        top: 39rem;
    }
    .imgBottom2{
        top:45rem;
        height:40px;
    }
    .textoY{
        top: 34rem;
    }
}
 /* For iPhone 4 Portrait or Landscape View */
 @media screen and (max-device-width: 380px){
    .hashtag {
        padding: 2vh 12vh 4vh 12vh;
    }
}

/* For iPhone 6 and 6 plus Portrait or Landscape View */
@media (min-device-height: 667px) and (min-device-width: 375px) and (-webkit-min-device-pixel-ratio: 3) {
}