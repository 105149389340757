/* Asegúrate de que las imágenes se ajusten correctamente */
.eventos-section {
  color: white;
  padding: 0;
}
.bold {
  font-weight: bold;
}
.tituloVenue {
  font-size: 4.7rem;
  font-weight: bold;
  margin: 0;
  padding: 0;
}
.tituloVenue2 {
  font-size: 4rem;
  font-weight: bold;
  margin: 0;
  padding: 0;
}

.distintos {
  font-style: italic;
  font-weight: bold;
}

.eventos-descripcion {
  margin-top: 20px;
  font-size: 1.7rem;
  padding: 2px;
}
.borde {
  border-radius: 10px;
}

.grande {
  font-size: 4rem;
}

.carrousel-descripcion {
  font-size: 35px;
  line-height: 2.0;
}
.seccion-blanco {
  background-color: #fff;
  color: black;
}
.eventos-boton {
  background-color: #fae94e; /* Ajusta el color si es necesario */
  border: none;
  width: 15rem;
  height: 4rem;
  border-radius: 40px;
  padding: 10px 20px;
  font-size: 1.1rem;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
}

/* Efecto hover para el botón */
.eventos-boton:hover {
  background-color: #ffdd00; /* Cambia ligeramente el color al pasar por encima */
}

.eventos-logo {
  scale: 180%;
  width: 20%;
  height: auto; /* Ajusta la altura según necesites */
}

/* Contenedor de la imagen de fondo */
.eventos-imagen-container {
  padding: 0px;
}

.eventos-imagen {
  width: 100%;
  height: auto;
}

/* Tamaño proporcional al ancho de la pantalla */
.tituloVenue {
  font-size: 5vw; /* Ajusta dinámicamente según el ancho de la ventana */
}
.contOfrece {
  padding: 5%;
}
.sociales-title{
  font-size: 6rem;
}
.tituloVenue3 {
  font-size: 2rem;
}
.carousel-item-height {
  height: 65vh;
}
/* Corrige el tamaño para pantallas más grandes */
@media (max-width: 900px) {
  .carousel-item-height {
    height: 60vh;
  }
  .tituloVenue3 {
    font-size: 1.5rem;
  }
  .sociales-title{
    font-size: 4rem;
  }
  .tituloVenue {
    font-size: 4rem;
  }
  .tituloVenue2 {
    font-size: 3rem;
  }
  .eventos-descripcion {
    padding: 15px;
    font-size: 1.3rem;
  }
  .eventos-boton {
    margin-top: 10px;
    height: 4rem;
  }
  .carrousel-descripcion {
    font-size: 20px !important;
  }
  .grande {
    font-size: 2.5rem;
  }
}

/* Nueva Sección "Coleccioná momentos" */
.momentos-section {
  position: relative;
  background-image: url('/public/momentos.webp'); /* Ajusta la ruta según corresponda */
  background-size: cover;
  background-position: center;
  padding: 80px 0;
  color: white;
  text-align: left;
}
.imgFondo1 {
  background-size: cover;
  background-position: center;
  background-image: url('/public/evento1.webp');
}
.feature-box {
  margin: 10px;
  padding: 20px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.7);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.offer-box {
  padding: 20px;
  color: #333;
  text-align: center;
}

.bg-yellow {
  border-radius: 10px;
  background-color: rgb(250, 233, 78);
  opacity: 0.77;
}

.offer-image {
  width: 100%;
  height: auto;
  clip-path: circle(50% at center);
}

.feature-content {
  text-align: center;
}

.feature-icon {
  font-size: 2rem;
  color: #ffc107;
  margin-bottom: 10px;
}
.social-icon {
  color: white;
  font-size: 2rem; /* Ajusta el tamaño según tus necesidades */
  margin: 0 10px; /* Espacio horizontal entre iconos */
  text-decoration: none; /* Elimina subrayado en enlaces */
}

.social-icon i {
  transition: color 0.3s; /* Transición suave al cambiar de color */
}

.social-icon:hover i {
  color: #ccc; /* Cambia el color al pasar el cursor */
}
