.nosotrosCarrousel img{
    width: 100%;
  }
.footer{
  margin-left:10vw;
  font-size:30px;
  margin-top:5rem;
}
.videoButtons{
    position: absolute;
    display: flex;
    justify-content: space-around;
    top:63vw;
    width: 100%;
    height: 60px;
  }
.nosotrosInfo strong{
    font-size: 20px;
    font-weight: 900;
    color: black;
}
.titulo{
  top:1.7vw !important;
  font-size: 5.4vw;
}
/* For Desktop View */
@media screen and (min-width: 1824px) {
    .videoButtons{
        top:75vh
    }
    .activeVideo {
        height: 60vh !important;
        width: 25vw !important;
      }
}
@media screen and (min-width: 2500px) {
    .videoButtons{
        top:80vh
    }
    .activeVideo {
        height: 70vh !important;
        width: 22vw !important;
      }
}
 
/* For Tablet View */
@media screen and (min-width: 768px)
and (max-width: 1024px) {
 .contenedorTorneo{
    text-align: center;
    justify-content: space-around;
 }
 .homeVideos video{
    height: 47vh;
 }
 .nosotrosInfo{
    text-align: center;
 }
 .activeVideo {
    position: absolute;
    z-index: 999;
    height: 75vh !important;
    width: 90vw !important;
  }
}
 
/* For Mobile Portrait View */
@media screen and (max-device-width: 480px)
and (orientation: portrait) {
 .nosotrosCarrousel img{
    max-height: 45vh !important;
 }
 .footer{
  margin-left:13vw;
  font-size:30px;
  margin-top:3rem;
  margin-bottom: 2rem;
}
 .nosotrosInfo strong {
    font-size: 15px !important;
  }
  .nosotrosInfo p {
    font-size: 16px !important;
  }
  .homeVideos video {
    min-height: 25vh;
}
.activeVideo {
    position: absolute;
    z-index: 999;
    height: 75vh !important;
    width: 90vw !important;
  }
.closeButton-active{
    display: initial;
    position: absolute;
    z-index: 1000;
    height: 40px;
    width:40px;
    top:83%;
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    filter:  invert(1) drop-shadow(3px 4px 6px black);
    background-size: 100% 100%;
  }
}
/* For Noteboock View */
@media screen and (max-height: 800px)
and (max-width: 1600px) and (orientation:landscape) {
.homeVideos{
max-height: 90vh;
padding-top: 1% !important;
}
.homeVideos video{
min-height: 70vh;
}
.nosotrosInfo{
height: 18vw !important;
}
.titulo{
  font-size: 73px;
  top: 23px !important;
}
#bottone1{
  padding-left: 1rem;
  padding-right: 1rem;
}
.nosotrosCarrousel img{
  max-height: 98vh !important;
}
.videoButtons {
    top: 45vw;
}
}
/* For Mobile Landscape View */
@media screen and (max-device-width: 640px)
and (orientation: landscape) {
  
}
 
/* For Mobile Phones Portrait or Landscape View */
@media screen and (max-device-width: 640px) {
  
}
 
/* For iPhone 4 Portrait or Landscape View */
@media screen and (min-device-width: 320px)
and (-webkit-min-device-pixel-ratio: 2) {
  
}
 
/* For iPhone 5 Portrait or Landscape View */
@media (device-height: 568px) and (device-width: 320px)
and (-webkit-min-device-pixel-ratio: 2) {
 
}
 
/* For iPhone 6 and 6 plus Portrait or Landscape View */
@media (min-device-height: 667px) and (min-device-width: 375px)
and (-webkit-min-device-pixel-ratio: 3) {
  
}