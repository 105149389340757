.textInicio strong{
    font-size: 16px;
}
.sponsor {
    filter: grayscale(1);
    max-height: 80px;
    width: 150px;
    object-fit: contain;
}
.contenedorSponsors{
display:flex;
align-items: center;
}
.storeButton img{
    max-width: 200px;
}
.imagenesInicio{
    padding: 3px;
    width: 25%;
    height:130% ;
}
.videoInicio{
    width: 100%;
}
.contenedorImagenesInicio{
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 5% 0% 5% 0%;
}
/* For Desktop View */
@media screen and (min-width: 1024px) {
    
}
 
/* For Tablet View */
@media screen and (min-device-width: 768px)
and (max-device-width: 1024px) {
 
}
 
/* For Mobile Portrait View */
@media screen and (max-device-width: 480px)
and (orientation: portrait) {
    .imgInicio {
        height: 50vh !important;
      }
      .textInicio{
        font-size: 12px !important;
    }
      .textInicio strong{
        font-size: 12px !important;
        font-weight: 600;
    }
    .contenedorSponsors{
        width:100vw;
        justify-content: space-evenly;
    }
    .sponsor {
        height: 45px;
        width: 75px;
      }
    .partners {
        max-height: 70px;
        width: 65px;
    }
}
 
/* For Mobile Landscape View */
@media screen and (max-device-width: 640px)
and (orientation: landscape) {
  
}
 
/* For Mobile Phones Portrait or Landscape View */
@media screen and (max-device-width: 640px) {
  
}
 
/* For iPhone 4 Portrait or Landscape View */
@media screen and (min-device-width: 320px)
and (-webkit-min-device-pixel-ratio: 2) {
    .divInicio{
        height: 23rem !important;
    }
}
 
/* For iPhone 5 Portrait or Landscape View */
@media (device-height: 568px) and (device-width: 320px)
and (-webkit-min-device-pixel-ratio: 2) {
 
}
 
/* For iPhone 6 and 6 plus Portrait or Landscape View */
@media (min-device-height: 667px) and (min-device-width: 375px)
and (-webkit-min-device-pixel-ratio: 3) {
  
}